









































import { Component, Vue } from "vue-property-decorator";
import MyList from "@/components/MyList.vue";
import Mixin from "@/js/mixins";

@Component({ components: { MyList }, mixins: [Mixin] })
export default class ShopDetail extends Vue {
  public user_id = "";
  public zoom = 12.8; // 地图缩放的大小
  public location = {}; // 地图定位地址
  public list: any[] = []; // 数据的列表
  public refresh = 0; // 页面允许刷新（为了list的改变）
  public select_shop = ""; // 选中的门店的index

  public search = ""; // 搜索框的值
  public search_value = ""; // 搜索的值

  public filteList = [
    {text:'全部',value:''},
    {text:'店中店',value:'1'},
    {text:'艾灸馆',value:'2'},
  ];
  public filteIndex = 0

  filte(index){
    this.filteIndex = index
    this.init()
  }

  // 搜索
  onSearch() {
    this.search_value = this.search;
  }
  // 地图上选中门店
  selectShop(id) {
    console.log(id)
    this.$router.push(`/list/order?id=${id}`)
    // this.select_shop = index;
    // let doc: any = window.document;
    // doc.getElementById("list_" + index).scrollIntoView();
    // this.location = {
    //   lng: this.list[index].user_address_info.lng,
    //   lat: this.list[index].user_address_info.lat
    // };
  }
  // 缩放后改变zoom的值
  zoomend(e) {
    this.zoom = e.target.getZoom();
  }

  // 获取地址
  clearList() {
    this.list = [];
    this.select_shop = "";
  }
  setList(list: any[]) {
    if (this.list.length === 0 && list.length) {
      this.location = {
        lng: list[0].user_address_info.lng,
        lat: list[0].user_address_info.lat
      };
    }
    this.list.push(...list);
    console.log(this.list)
  }

  init() {
    this.user_id = String(this.$route.query.user_id || "");
    this.zoom = 12.8;
    this.location = this.$globalData.location;
    this.select_shop = "";
    this.search = "";
    this.search_value = "";
    this.list = [];
    this.refresh++;
  }
}
